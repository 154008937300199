(function($){
    var animations = {};

    $('.lottie-animation').each(function(k,container) {
        var $container = $(container);
        animations[$container.attr('id')] = lottie.loadAnimation({
            container: container,
            renderer: 'svg',
            loop: !!$container.attr('data-lottie-loop'),
            autoplay: !!$container.attr('data-lottie-autoplay'),
            path: $container.attr('data-lottie-src')
        });
    });

    document.addEventListener('aos:in:lottie', function(e) {
        var detail = e.detail;
        if (detail.nodeType !== 1) {
            detail = e.detail.detail;
        }

        var target = detail.dataset.lottieTarget;
        var delay = detail.dataset.aosDelay || 0;

        if(animations[target]) {
            setTimeout(function(){
                animations[target].play();
            }, parseInt(delay));
        }
    });

    AOS.init({
        once: true
    });
})(jQuery);