(function($){
    var partnerList = {
        list: undefined,
        categoryFilter: 'all',
        options: {
            valueNames: [{ data: ['category']}]
        },
        filterList: function(e) {
            var filter = $(this).data('filter').split('-');

            partnerList[filter[0] + 'Filter'] = filter[1];

            partnerList.list.filter(function(item) {
                var categoryFilter = false;

                if (partnerList.categoryFilter == 'all') {
                    categoryFilter = true;
                } else {
                    categoryFilter = item.values().category == partnerList.categoryFilter;
                }

                return categoryFilter;
            });
        },
        addEventListeners: function() {
            $('.partners-filters [data-filter]').on('click', partnerList.filterList);
            partnerList.list.on('updated', function (list) {
                return list.matchingItems.length > 0 ? $('#partners-list .notification').addClass('d-none') : $('#partners-list .notification').removeClass('d-none');
            });
        },
        init: function() {
            if ($('#partners-list')) {
                partnerList.list = new List('partners-list', partnerList.options);
                partnerList.addEventListeners();
            }
        }
    };
    partnerList.init();
})(jQuery);