(function(){
    $('#featureAccordion').on('show.bs.collapse', function (e) {
        //when opened add class 'open' to nearest parent .card
        var card = $(e.target).closest('.card');
        card.addClass('open');
        $('.collapsible-feature-block .image-content img').removeClass('active');
        $('.collapsible-feature-block .image-content img[data-image="' + card.attr('data-image') + '"]').addClass('active');
    });
    $('#featureAccordion').on('hide.bs.collapse', function (e) {
        //when hidden remove class 'open' from nearest parent .card
        $(e.target).closest('.card').removeClass('open');
    });
})();