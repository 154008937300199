(function(){
    $('.navbar .navbar-collapse')
        .on('hide.bs.collapse', function (e) {
            $('.hamburger').removeClass('is-active');
            $('body').removeClass('lock-scroll');
        })
        .on('show.bs.collapse', function (e) {
            $('.hamburger').addClass('is-active');
            $('body').addClass('lock-scroll');
        });

    $('.nav.navbar-nav > .nav-item > a.nav-link').on('click', function(e) {
        var $this = $(this);
        var activeWidth = $this.innerWidth();
        var itemPos = $this.position();
        $('.nav-indicator').css({
            'left': itemPos.left + 'px',
            'width': activeWidth + 'px'
        });

        if ($this.attr('data-action') && $this.attr('data-action') == 'login') {
            setTimeout(function() {
                $('.login-form [name="username"]').focus();
            }, 350);
        }
    });

    $.get('https://status.noctel.com/api/v1/components?per_page=999', function(result) {
        if (result.hasOwnProperty('data')) {
            var hasError = result.data.filter(function(component){
                return component.status !== "1";
            }).length > 0;

            if (hasError) {
                $('[data-role="status-indicator"]').addClass('down');
            }
        }
    });

    $(window).on('scroll', function() {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            $('#topNav').addClass('shrink');
        } else {
            $('#topNav').removeClass('shrink');
        }
    });

})(jQuery);