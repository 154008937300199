(function($){
    var teamMember = {
        currentColumnCount: false,
        activeTeamMember: false,
        teamMemberSelector: '.team-block [data-role="team-member"]',
        bioTemplates: $('#bio-templates').html(),
        toggleBio: function(e) {
            var member = $(this);
            if(member.find('.card').hasClass('active')) {
                teamMember.removeBio();
            } else {
                teamMember.addBio(member);
            }
        },
        addBio: function(member) {
            if (!teamMember.currentColumnCount) {
                teamMember.getColumnCount();
            }

            teamMember.removeBio();

            teamMember.activeTeamMember = member;
            teamMember.activeTeamMember.find('.card').addClass('active');

            var teamMemberIndex = $(teamMember.teamMemberSelector).index(teamMember.activeTeamMember) + 1;
            var offset = Math.ceil(teamMemberIndex / teamMember.currentColumnCount) * teamMember.currentColumnCount;
            var offset = offset > $(teamMember.teamMemberSelector).length ? $(teamMember.teamMemberSelector).length - 1 : offset - 1;

            var bio = $(teamMember.bioTemplates).find('[data-bio="' + teamMember.activeTeamMember.attr('data-bio') + '"]').clone();

            $(teamMember.teamMemberSelector).eq(offset).after(bio);
            $('.team-block .team-container [data-role="bio"]').slideDown();
        },
        removeBio: function() {
            if (teamMember.activeTeamMember) {
                teamMember.activeTeamMember.find('.card').removeClass('active');
                $('.team-block .team-container [data-role="bio"]').slideUp('normal', function() {
                    $(this).remove();
                });
                teamMember.activeTeamMember = false;
            }
        },
        getColumnCount: function() {
            if(window.innerWidth < 768) {
                teamMember.currentColumnCount = 1;
            } else if(window.innerWidth >= 768 && window.innerWidth < 1200) {
                teamMember.currentColumnCount = 2;
            } else {
                teamMember.currentColumnCount = 4;
            }
        },
        resize: function() {
            teamMember.removeBio();
            teamMember.getColumnCount();
        },
        addEventListeners: function() {
            $(window).on('resize', teamMember.resize);
            $(teamMember.teamMemberSelector).on('click', teamMember.toggleBio);
        },
        init: function() {
            teamMember.addEventListeners();
        }
    };
    teamMember.init();
})(jQuery);