(function($){
    var hardwareList = {
        list: undefined,
        brandFilter: 'all',
        categoryFilter: 'all',
        options: {
            valueNames: [{ data: ['category', 'brand']}]
        },
        filterList: function(e) {

            if ($(this).hasClass('dropdown-item')) {
                e.stopPropagation();
            }

            var filter = $(this).data('filter').split('~');

            if (filter[0] == 'category') {
                hardwareList[filter[0] + 'Filter'] = filter[1];
            } else {
                if (filter[1] == 'all') {
                    $('a[data-filter^="' + filter[0] + '~"] input').prop('checked', false);
                    $(this).find('input').prop('checked', true);
                    hardwareList[filter[0] + 'Filter'] = 'all';
                } else {
                    $('a[data-filter^="' + filter[0] + '~all"] input').prop('checked', false);
                    var checkedFilters = $('a[data-filter^="' + filter[0] + '~"] input:checked');

                    hardwareList[filter[0] + 'Filter'] = [];
                    checkedFilters.each(function(){
                        hardwareList[filter[0] + 'Filter'].push($(this).closest('[data-filter]').data('filter').split('~')[1]);
                    });
                }
            }

            hardwareList.list.filter(function(item) {
                var brandFilter = false;
                var categoryFilter = false;

                if (hardwareList.brandFilter == 'all') {
                    brandFilter = true;
                } else {
                    brandFilter = $.inArray(item.values().brand, hardwareList.brandFilter) > -1;
                }

                if (hardwareList.categoryFilter == 'all') {
                    categoryFilter = true;
                } else {
                    categoryFilter = item.values().category == hardwareList.categoryFilter;
                }

                return brandFilter && categoryFilter;
            });
        },
        addEventListeners: function() {
            $('.hardware-filters [data-filter]').on('click', hardwareList.filterList);
            hardwareList.list.on('updated', function (list) {
                return list.matchingItems.length > 0 ? $('#hardware-list .notification').addClass('d-none') : $('#hardware-list .notification').removeClass('d-none');
            });
        },
        init: function() {
            if ($('#hardware-list')) {
                hardwareList.list = new List('hardware-list', hardwareList.options);
                hardwareList.addEventListeners();

                var urlCategory = getUrlParameter('category');
                if (urlCategory) {
                    $('.hardware-filters [data-filter="category~' + urlCategory + '"]').click();
                }
            }
        }
    };
    hardwareList.init();
})(jQuery);