(function(){
    $('.slick-carousel').slick({
        'mobileFirst': true,
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'arrows': false,
        'dots': true,
        'infinite': true,
        'responsive': [
            {
                'breakpoint': 767,
                'settings': {
                    'slidesToShow': 2,
                    'slidesToScroll': 2
                }
            },
            {
                'breakpoint': 1199,
                'settings': {
                    'slidesToShow': 3,
                    'slidesToScroll': 3
                }
            }
        ]
    });
})();