(function($){
    var blogList = {
        list: undefined,
        categoryFilter: 'all',
        options: {
            valueNames: [{ data: ['category']}]
        },
        filterList: function(e) {
            if ($(this).hasClass('dropdown-item')) {
                e.stopPropagation();
            }

            var filter = $(this).data('filter').split('~');

            if (filter[1] == 'all') {
                $('a[data-filter^="' + filter[0] + '~"] input').prop('checked', false);
                $(this).find('input').prop('checked', true);
                blogList[filter[0] + 'Filter'] = 'all';
            } else {
                $('a[data-filter^="' + filter[0] + '~all"] input').prop('checked', false);
                var checkedFilters = $('a[data-filter^="' + filter[0] + '~"] input:checked');

                blogList[filter[0] + 'Filter'] = [];
                checkedFilters.each(function(){
                    blogList[filter[0] + 'Filter'].push($(this).closest('[data-filter]').data('filter').split('~')[1]);
                });
            }

            blogList.list.filter(function(item) {
                var categoryFilter = false;

                if (blogList.categoryFilter == 'all') {
                    categoryFilter = true;
                } else {
                    categoryFilter = $.inArray(item.values().category, blogList.categoryFilter) > -1;
                }

                return categoryFilter;
            });
        },
        addEventListeners: function() {
            $('.blog-filters [data-filter]').on('click', blogList.filterList);
            blogList.list.on('updated', function (list) {
                return list.matchingItems.length > 0 ? $('#blog-list .notification').addClass('d-none') : $('#blog-list .notification').removeClass('d-none');
            });
        },
        init: function() {
            if ($('#blog-list')) {
                blogList.list = new List('blog-list', blogList.options);
                blogList.addEventListeners();
            }
        }
    };
    blogList.init();
})(jQuery);