(function($){
    var autoCollapse = {
        moreMenuWidth: 0,
        oldWindowWidth: 0,
        $nav: $('nav[data-style="auto-collapse"]'),
        $collapse: $('nav[data-style="auto-collapse"] .collapse > ul[data-role="menu"]'),
        $moreMenu: $('nav[data-style="auto-collapse"] [data-role="more-menu"]'),
        contract: function() {
            var width = 0,
                outerWidth = autoCollapse.$nav.outerWidth() - autoCollapse.moreMenuWidth - 50,
                collapseChildren = autoCollapse.$collapse.children('li');
            for (var i = 0; i < collapseChildren.length; i++) {
                width += collapseChildren.eq(i).outerWidth();

                if (width > outerWidth) {
                    collapseChildren.eq(i - 1).nextAll()
                        .detach()
                        .prependTo(autoCollapse.$moreMenu.find('ul'));
                    break;
                }
            }
            autoCollapse.toggleMoreMenu();

            var $activeMenuItem = $('nav[data-style="auto-collapse"] .navbar-nav > li.nav-item.active');
            autoCollapse.setIndicatorPosition($activeMenuItem);
        },
        expand: function() {
            autoCollapse.$moreMenu.find('ul > li')
                .detach()
                .appendTo(autoCollapse.$collapse);
            autoCollapse.toggleMoreMenu();
            autoCollapse.contract();
        },
        toggleMoreMenu: function() {
            if (autoCollapse.$moreMenu.find('ul > li').length > 0) {
                autoCollapse.$moreMenu.children('li').removeClass('d-none');
            } else {
                autoCollapse.$moreMenu.children('li').addClass('d-none');
            }
            autoCollapse.setMoreMenuWidth();
        },
        isMoreMenuVisible: function() {
            return !autoCollapse.$moreMenu.children('li').hasClass('d-none');
        },
        setMoreMenuWidth: function() {
            autoCollapse.moreMenuWidth = autoCollapse.$moreMenu.outerWidth();
        },
        getNavWidth: function() {
            var width = 0;
            if (autoCollapse.isMoreMenuVisible()) {
                width += autoCollapse.moreMenuWidth;
            }
            width += autoCollapse.$collapse.outerWidth();
            return width;
        },
        setInitialIndicatorPosition: function() {
            var $firstItem = $('nav[data-style="auto-collapse"] .collapse > ul[data-role="menu"] li:first-child');
            autoCollapse.setIndicatorPosition($firstItem);
        },
        setIndicatorPosition: function($menuItem) {

            // If the menu item is in the more menu, use the more menu instead
            if (!$menuItem.is(':visible')) {
                $menuItem.addClass('active');
                $menuItem = $('nav[data-style="auto-collapse"] [data-role="more-menu"] > li.nav-item');
            } else {
                $('nav[data-style="auto-collapse"] [data-role="more-menu"] > li.nav-item').removeClass('active');
            }

            var activeWidth = $menuItem.innerWidth();
            var itemPos = $menuItem.position();

            $('nav[data-style="auto-collapse"] .navbar-nav > li.nav-item').removeClass('active');
            $menuItem.addClass('active');

            $('[data-style="auto-collapse"] .indicator').css({
                'left': itemPos.left + 'px',
                'width': activeWidth + 'px'
            });
        },
        onResize: function() {
            var windowWidth = $(window).innerWidth();

            if (windowWidth < autoCollapse.oldWindowWidth) {
                autoCollapse.contract();
            } else {
                autoCollapse.expand();
            }

            autoCollapse.oldWindowWidth = windowWidth;
        },
        onClick: function(e) {
            autoCollapse.setIndicatorPosition($(this).closest('li'));
        },
        addEventListeners: function() {
            $(window).on('resize', autoCollapse.onResize);
            $('nav[data-style="auto-collapse"] .navbar-nav > li.nav-item a').on('click', autoCollapse.onClick);
        },
        init: function() {
            if (autoCollapse.$nav.length > 0) {
                // Init menu
                autoCollapse.addEventListeners();
                autoCollapse.oldWindowWidth = $(window).innerWidth();
                autoCollapse.setInitialIndicatorPosition();

                if (autoCollapse.getNavWidth() > autoCollapse.$nav.outerWidth()) {
                    autoCollapse.contract();
                }
            }
        }
    };

    autoCollapse.init();
})(jQuery);