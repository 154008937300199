(function($){
    $('form[data-type="mailchimp-ajax"]').on('submit', function(e) {
        e.preventDefault();
        $form = $(this);
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            cache: false,
            dataType: 'jsonp',
            jsonp: 'c',
            contentType: 'application/json; charset=utf-8',
            error: function() {
                $form.find('.success-message').text('Could not connect to the registration server. Please try again later.');
            },
            success: function(data) {
                if (data.result != 'success') {
                    $form.find('.success-message').html(data.msg);
                } else {
                    $form.find('.success-message').text('Thank you! Please check your email to confirm your subscription.');
                }
            },
            complete: function() {
                $form.find('.success-message').addClass('active');
                setTimeout(function() {
                    $form[0].reset();
                    $form.find('.success-message').removeClass('active');
                }, 3000);
            }
        });
    });
})(jQuery);